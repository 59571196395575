<template>
  <v-container fluid>

    <v-row>
      <v-col cols="12" md="12" lg="6">
        <v-card>
          <v-card-title class="blue-grey lighten-2 text_on_primary_background py-1 mb-6">Rendimento</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <v-text-field label="Prezzo sottostante"
                              hint="Prezzo del sottostante oggi"
                              v-model="prezzoSottostante"
                              append-icon="mdi-currency-eur"

                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-text-field label="Strike Price Lev" hint="Strike Price Level"
                              v-model="strikePriceLevel"></v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col >
                <MjResult label="Distanza dallo strike price"
                          :value="distanzaDaStrikePrice" sign="%"/>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="12" lg="6">
        <v-card>
          <v-card-title class="blue-grey lighten-2 text_on_primary_background py-1 mb-6">Profitto</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Capitale iniziale"
                              v-model="capitaleIniziale"
                              append-icon="mdi-currency-eur"

                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-8">
              <v-col cols="12">
                <v-slider v-model="rendimentoAtteso" thumb-size="40" hint="Rendimento Atteso" persistent-hint
                          thumb-label="always" step="1">
                  <template v-slot:thumb-label="{ value }">
                    {{ value }} %
                  </template>
                </v-slider>
              </v-col>
            </v-row>
            <v-row class="mt-12">
              <v-col cols="12">
                <v-slider v-model="prezzoDiSicurezza" thumb-size="40" hint="Prezzo di Sicurezza" persistent-hint
                          thumb-label="always" step="1" max="500">
                  <template v-slot:thumb-label="{ value }">
                    {{ value }}
                  </template>
                </v-slider>
              </v-col>
            </v-row>
            <v-row class="mt-12">

              <v-col cols="12">
                <v-progress-linear :value="punteggioRischioPercentage"
                                   disabled=""
                                   height="25"

                                   :color="punteggioRischioColor"
                                   background-color="grey lighten-2">
                  <template v-slot:default="{  }">
                    <strong>
                      {{
                        (punteggioRischio) ? new Intl.NumberFormat("it", {
                          maximumFractionDigits: 2,

                        }).format(punteggioRischio) : 'N.D.'
                      }}</strong>
                  </template>
                </v-progress-linear>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="12" lg="6">
                <v-text-field label="Valore Contratto"
                              v-model="valoreContratto"
                ></v-text-field>
              </v-col>
              <v-col md="12" lg="6">
                <v-text-field label="Prezzo Simulato"
                              v-model="prezzoSimulato"
                              append-icon="mdi-currency-eur"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-simple-table class="mj_table">
              <thead>
              <tr >
                <th class="blue-grey text_on_primary_background">Premio Mensile Incassato</th>
                <th class="blue-grey text_on_primary_background">Prezzo Apertura</th>
                <th class="blue-grey text_on_primary_background">Valore Max Contratti</th>
                <th class="blue-grey text_on_primary_background">N° Max Contratti</th>
                <th class="blue-grey text_on_primary_background">Profitto Perdita Simulata</th>
              </tr>
              </thead>
              <tbody>
              <tr>
              <td class="text-right">
                <MjResult :value="premioMensileIncassato" sign="€"/>
              </td>
              <td class="text-right">
                <MjResult :value="prezzoApertura" sign=""/>
              </td>
              <td class="text-right">
                <MjResult :value="valoreMaxContratti" sign=""/>
              </td>
              <td class="text-right">
                <MjResult :value="numeroMaxContratti" sign=""/>
              </td>
              <td class="text-right">
                <MjResult :value="profittoPerditaSimulata" sign="€"/>
              </td>
              </tr>
              </tbody>

            </v-simple-table>

          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>


import MjResult from "@/components/MjResult";

export default {
  name: "CalculatorView",
  components: {MjResult},
  computed: {
    distanzaDaStrikePrice: function () {
      return ((this.prezzoSottostante - this.strikePriceLevel) / this.prezzoSottostante * 100)
    },
    punteggioRischio: function () {
      return Math.min(400, 10000 * (this.valoreMaxContratti / this.capitaleIniziale));
    },
    punteggioRischioPercentage: function () {
      return this.punteggioRischio * 100 / this.maxPunteggioRischio;
    },
    premioMensileIncassato: function () {
      return this.capitaleIniziale * this.rendimentoAtteso / 100;
    },
    prezzoApertura: function () {
      return this.premioMensileIncassato / this.valoreMaxContratti;
    },
    valoreMaxContratti: function () {
      return (this.capitaleIniziale * (1 + this.rendimentoAtteso / 100)) / this.prezzoDiSicurezza;
    },
    numeroMaxContratti: function () {
      return this.valoreMaxContratti / this.valoreContratto;
    },
    profittoPerditaSimulata: function () {
      return (this.prezzoApertura - this.prezzoSimulato) * this.valoreMaxContratti;
    },

    punteggioRischioColor: function () {
      let color = 'green';
      if (this.punteggioRischio > 70 && this.punteggioRischio <= 170) color = 'yellow';
      if (this.punteggioRischio > 170 && this.punteggioRischio <= 270) color = 'orange';
      if (this.punteggioRischio > 270) color = 'red';
      return color;
    },

  },
  data: () => ({
    prezzoSottostante: 0,
    strikePriceLevel: 0,
    capitaleIniziale: 1000,
    rendimentoAtteso: 20,
    prezzoDiSicurezza: 230,
    prezzoSimulato: 1,
    valoreContratto: 1,
    maxPunteggioRischio: 400,


  })
}
</script>

<style scoped>
.mj_table td,.mj_table th
{

  border: solid 1px #ccc !important;
  border-collapse: collapse ;

}


</style>