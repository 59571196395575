<template>
  <v-sheet flat color="transparent" >
    <v-layout row justify-left align-center>
      <v-icon small class="mr-1 lighten-5" >{{ icon }}</v-icon>
      <div class="text--secondary text-uppercase second_font"> {{
          title
        }}
      </div>
    </v-layout>
  </v-sheet>
</template>
<style scoped>

</style>
<script>
export default {
  name: "MjSoftTitle",
  props:{
    "title":{default: ()=>''},
    "icon":{default: ()=>''}
  }
}
</script>

