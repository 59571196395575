import mj_axios from "@/mj_axios.js";
import mj_config from "@/mj_config.js";
const formMixin = {
    props:{
        routerReplaceAfterSave: {default: () => true},
        forceDisableBackOnSave:{default: () => false}
    },
    data: function () {
        return {
            restore_item_show: false,
            error_text: "",
            error_show: false,
            loading_overlay: false,
            valid: false,
            lazy: false,
            edit_not_saved: false,

            selects: [],
            load_sel_promises: [], //messo in data per usarlo nei componenti per personalizzazioni da fare dopo il caricamento delle selects.
        };
    },
    computed: {
        editMode: function () {
            const editMode = (this.$route.params != null && this.$route.params.id != null) /*|| (this.form.id  && this.form.id["val"] != null)*/;
            return Boolean(editMode);
        },

    },
    created() {
        this.init();
    },

    methods: {
        init() {
            return new Promise((resolve, reject) => {
                this.load_sel_promises = this.loadSelects();

                Promise.all(this.load_sel_promises).finally(() => {
                    this.loading_overlay = false;
                    if (this.editMode) {
                        this.loadItem(null, true).then(resolve).catch(reject);
                    } else {
                        //il reset è necessario per v-autocomplete.
                        //infatti cambiando i valori del v-model il componente si aggiorna e prende i nuovi items caricati tramite axios
                        this.resetForm();
                        resolve();
                    }
                });
            });
        },
        loadSelects() {
            let axios_promises = [];
            this.loading_overlay = true
            let wait_for_ajax = false;
            Object.keys(this.form).forEach(key => {

                const obj = this.form[key];
                //se nessuna selects viene caricata, allora dobbiamo fare (basandoci su questa var) il resolve alla fine del ciclo
                //i campi della form che necessitano select esterne hanno la prop loadSelect = true e l'apiUrl
                if (obj) {
                    if (obj.loadSelect) {
                        wait_for_ajax = true;
                        const resp = mj_axios.get(obj.apiUrl)
                            .then(response => {
                                this.selects[key] = response.data.data;
                                // this.loading_overlay = false;

                            }).catch(() => {
                                // this.error_show = true;
                                // this.error_text = "Errore nel caricamento dati";
                                // this.loading_overlay = false;
                            });
                        axios_promises.push(resp);
                    }
                }
            });
            if (wait_for_ajax == false) {
                this.loading_overlay = false;
            }
            return axios_promises;
        },
        goBack() {
            //chiudo la window se non ho history
            if (window.history.length === 1)
            {
                //la funzione viene ignorata se non è stato aperto tramite window.open
                window.close();
            }


            // window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/');
            this.$router.go(-1);
        },
        newItem() {
            if (this.editMode) {
                this.$router.replace(
                    this.$route.path.replace(this.$route.params.id, "")
                );
            }
            Promise.all(this.loadSelects()).then(() => {
                this.loading_overlay = false
            }); //ricarico le select perchè in caso di v-combobox i nuovi elementi inseriti devono essere ricaricati.
            this.resetForm();
            if (this.$refs.mj_uploader) {
                this.$refs.mj_uploader.reset();
            }
        },
        resetForm() {

            Object.keys(this.form).forEach(key => {
                let default_value = this.form[key]['default_val'];
                if (!default_value) default_value = "";
                this.form[key]['val'] = default_value;
            });

            this.restore_item_show = false;
            this.error_show = false;
        },

        loadItem(item_id, load_allegati) {

            let id = null;
            if (item_id == null) {
                // if (this.form.id && this.form.id.val) {
                //     id = this.form.id.val;
                // } else
                    if (this.$route.params.id){

                    id = this.$route.params.id;
                }
                else { id = null;}
            } else {
                id = item_id
            }
            this.loading_overlay = true;

            const axios_promise = mj_axios
                .get(this.apiUrl + "/" + id).then(response => {

                    if (response.data) {
                        const data = response.data.data;

                        //devi mantere gli stessi nomi delle api sulla form
                        Object.keys(data).forEach(key => {
                            const field = this.form[key];
                            if (field) {
                                // const field_type = field.type;
                                field.val = data[key];
                            }
                        });

                        if (data.deleted_at)
                        {
                            this.restore_item_show = true;
                            this.error_text = `Stai lavorando su un elemento cestinato, clicca su ripristina per ripristinarlo fuori dal cestino.`;
                            this.error_show = true;

                        }
                    }




                    //chain for upload solo se esiste mjUploader
                    if (this.$refs.mj_uploader && id && load_allegati) {
                        return this.$refs.mj_uploader.load(id);
                    }

                }).then(() => {
                    //gestione caricamento allegati con successo
                }).catch(e => { //unico catch


                    const status = (e.response) ? e.response.status : null;
                    const message = (e.response) && (e.response.data) ? e.response.data.message : null;

                    if (status == 403) {
                        this.error_text = "Operazione non consentita (403)";
                    } else {
                        this.error_text = `Errore nel caricamento dati ${status ? status : ''} ${message}`;
                    }
                    this.error_show = true;

                })
                .then(() => {
                    this.loading_overlay = false;
                    this.edit_not_saved = false; //deve essere messo sul secondo then altrimenti l'assegnamento arriva prima watch
                });

            return axios_promise;
        },
        saveItem: function () {
            this.$forceUpdate();
            return new Promise((resolve, reject) => {
                if (this.$refs.form.validate()) {



                    this.loading_overlay = true;
                    this.error_show = false;

                    let params = {};
                    Object.keys(this.form).forEach(key => {
                        const obj = this.form[key];
                        if (obj)
                            if (obj.type === 'array') {
                                //JSON trick per convertire l'array vue in array normale in modalità deep

                                params[key] = JSON.parse(JSON.stringify(obj.val));
                            } else {
                                params[key] = obj.val;
                            }
                    });

                    let axios_promise = null;

                    let id = null;
                    if (this.editMode) {

                        // if (this.form.id && this.form.id.val) {
                        //     id = this.form.id.val;
                        // } else {
                            id = this.$route.params.id;
                        // }

                        axios_promise = mj_axios.put(this.apiUrl + "/" + id, params); //edit
                    } else {
                        axios_promise = mj_axios.post(this.apiUrl, params); //insert
                    }

                    axios_promise
                        .then(response => {
                            if (response.data) {
                                const item = response.data.data;

                                id = item.id ? item.id.toString() : item.cod;
                                if (this.editMode == false) {
                                    //passo da modalità insert ad update (Solo la prima volta)

                                    //supporto il caso in cui l'item id non si chiama id, ma cod
                                    //to string è necessario per il router, se passi un int si incazza.
                                    if (this.routerReplaceAfterSave)
                                    {this.$store.commit('popToModalNavigationBreadcumbs');
                                    this.$router.replace({path: id, append: true});}
                                }
                                this.edit_not_saved = false;

                                //chain for upload solo se esiste mjUploader
                                if (this.$refs.mj_uploader) {
                                    return this.$refs.mj_uploader.upload(id);
                                }
                            }
                        })
                        .then(() => {
                            //then dopo upload.. adesso mi occupo di cancellare quelli marcati come "da cancellare nella list item"
                            if (this.$refs.mj_uploader) {
                                return this.$refs.mj_uploader.deleteSelected(id);
                            }
                        })
                        .then(() => {
                            //then dopo deleteExisting
                        })
                        .catch(e => {
                            const status = (e.response) ? e.response.status : null;
                            let message = (e.response) && (e.response.data) ? e.response.data.message : null;

                            this.error_show = true;

                            //soft deleted item detected
                            if (status == 409) {
                                // this.restore_item_show = true;
                                const id = e.response.data.data.id.toString();
                                this.$router.replace({path: id, append: true});

                                //dopo il replace viene ricaricata la pagina e il msg non viene più mostrato
                                // message = 'Stai cercando di inserire un elemento che è stato già cancellato, se vuoi ripristinarlo clicca su "ripristina"';
                                //si opta su load item di mostrarlo se deleted_at ha valore.


                            } else {
                                if (e.response && e.response.data && e.response.data.message) {
                                    message = e.response.data.message;
                                }
                            }
                            this.error_text = `Errore nel Salvataggio dati  ${message ? message : ''}`;
                            this.error_show = true;
                            reject();
                        })
                        .then(() => {
                            this.loading_overlay = false;
                            resolve();
                            if (this.error_show === false && mj_config.backOnSave() && this.forceDisableBackOnSave == false)
                            {
                                this.$router.go(-1);
                            }
                            this.$emit("saved");
                        });
                } else {
                    const fieldHasError = this.$refs.form.inputs.find(field => field.hasError);
                    // this.$vuetify.goTo(fieldHasError);
                    fieldHasError.focus();
                }
            });
        },
        restoreItem: function () {
            //resuscita un item cancellato in modalità soft da laravel
            this.form.deleted_at = {type: "datetime", val: null};
            this.form.restore = {type: "boolean", val:true};
                this.saveItem().then(() =>{
                    delete this.form.restore;
                    this.restore_item_show = false;
            });
        }
    },
    watch: {
        form: {
            handler: function () {
                // this.restore_item_show = false;
                this.edit_not_saved = true;
                return true;
            },
            deep: true
        },


    }
};

export default formMixin;
