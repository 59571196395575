<template>
  <v-container fluid>
    <div class="mx-auto" style="max-width: 480px">
      <v-progress-linear v-if="showLoader" indeterminate></v-progress-linear>
    </div>
    <v-row class="mr-2 ">
      <v-col v-for="item in items" :key="item.id">
        <v-card>
          <v-card-title
              class="text-uppercase text-truncate small  lighten-1 border_bottom py-1 primary text_on_primary_background">
            {{ item.title }}
          </v-card-title>
          <v-card-subtitle v-if="item.sub_title">{{ item.sub_title }}</v-card-subtitle>
          <v-card-text class="gradient_background pa-0 ">
            <v-list dense class="transparent pt-0 mt-1">
              <router-link v-for="child in item.all_children" :key="child.id" :to="(child.link) ? child.link : ''"
                           class="no_underline">
                <v-list-item link :single-line="!child.sub_title">
                  <v-list-item-icon class="mr-3">
                    <v-icon>{{ child.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ child.title }}</v-list-item-title>
                    <v-list-item-subtitle>{{ child.sub_title }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </router-link>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mj_axios from "@/mj_axios.js";

export default {
  name: "MjMenuCard",

  props: {
    menuItemsApiUrl: {required: true},
  },
  data: () => ({
    items: [],
    showLoader: false
  }),

  created() {
    this.showLoader = true;
    mj_axios
        .get(this.menuItemsApiUrl)
        .then(response => {
          if (response.data.data)
            this.items = Object.values(response.data.data);
        })
        .then(() => {
          // always executed
          this.showLoader = false;
        });
  },
  methods: {
    //TODO da implementare per la ricerca (in modo ricorsivo perchè viene richiamato solo su quelli di livello 0)
    filter: function (/*item, search, textKey*/) {


      // return item[textKey].indexOf(search) > -1;
      return true;
    }
  }
};
</script>
