<template>
  <v-container>
    <MjEntityForm :title="'Utente ' + (($route.params.id) ? $route.params.id : 'Nuovo') " :form="form" :apiUrl="apiUrl">
    <template #fields="slotProps">
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field v-model="form.id.val" label="Id Utente" disabled required></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field v-model="form.name.val" :rules="[rules.required]" label="Nome Utente"></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field v-model="form.email.val" :rules="[rules.required]" label="Email"></v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-text-field
              v-model="form.password.val"
              type="password"
              :rules="slotProps.editMode ? [] : [rules.required]"
              label="Password"
              autocomplete='off'
              persistent-hint
              hint="Password non registrata nel sistema per motivi di sicurezza. Se vuoi cambiarla scrivi la nuova password nel campo di testo."
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="8">
          <!-- filter è un workaround -->
          <v-autocomplete
              v-model="form.role.val"
              item-text="name"
              item-value="id"
              :items="slotProps.selects.role"
              cache-items
              return-object
              label="Ruoli"
          ></v-autocomplete>
        </v-col>
        <v-col md="4">
          <v-switch v-model="form.active.val" label="Utente Attivo"></v-switch>
        </v-col>
      </v-row>
    </template>
    </MjEntityForm>
  </v-container>
</template>

<script>
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";
import MjEntityForm from "@/components/form/MjEntityForm";

export default {
  name: "UserFormView",
  mixins: [ ValidationRulesMixin],
  components:{MjEntityForm},

  data: () => ({
    form: {
      id: {type: "integer", val: "", default_val: ""},
      name: {type: "string", val: ""},
      email: {type: "string", val: ""},
      password: {type: "string", val: ""},
      active: {type: "boolean", val: ""},
      role: {
        type: "object",
        apiUrl: "/api/user/roles",
        loadSelect: true,
        val: {name: "", id: ""}
      }
    },
    apiUrl: "/api/user/users"
  }),

  methods: {}
};
</script>
