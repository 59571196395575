import { render, staticRenderFns } from "./MjMenuTree.vue?vue&type=template&id=4a3af98c&scoped=true&"
import script from "./MjMenuTree.vue?vue&type=script&lang=js&"
export * from "./MjMenuTree.vue?vue&type=script&lang=js&"
import style0 from "./MjMenuTree.vue?vue&type=style&index=0&id=4a3af98c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a3af98c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VContainer,VIcon,VListItem,VListItemContent,VListItemIcon,VListItemSubtitle,VListItemTitle,VTooltip,VTreeview})
