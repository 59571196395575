<template>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy" @submit.prevent="submit">
      <v-text-field
          outlined
          background-color="white"
          v-model="form.email"
          persistent-placeholder
          :append-icon="'mdi-email-outline'"
          :rules="[rules.email]"
          label="E-mail"
          type="email"
          required
      ></v-text-field>
      <v-text-field
          outlined
          background-color="white"
          v-model="form.password"
          autocomplete="off"
          persistent-placeholder
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          :rules="[rules.min(8)]"
          label="Password"
          @click:append="showPassword = !showPassword"
          required
      ></v-text-field>
      <v-btn :disabled="!valid" type="submit" large color="primary" block class="mr-4">Accedi</v-btn>
      <v-alert v-if="show_error" type="warning" class="mt-2 py-2 warning">
        <span v-text="login_error_text"></span>
      </v-alert>
      <v-row  class="mt-2 mb-0">
        <v-col>
          Hai dimenticato la password?
          <router-link to="/utente/recupera_password">Recupera adesso</router-link>
        </v-col>
        </v-row>
      <v-row v-if="allow_registration" class="mt-0">
        <v-col>
          Non hai un account?
          <router-link to="/utente/registrati">Registrati</router-link>
        </v-col>
      </v-row>
    </v-form>
</template>

<script>
import store from "@/store.js";
import mj_config from "@/mj_config.js";
import ValidationRulesMixin from "@/mixins/ValidationRulesMixin.js";


export default {
  name: "MjLogin",
  components: {},
  mixins: [ValidationRulesMixin],
  store,

  //definiamo i componenti che desideriamo utilizzare in questo componente per evitare che venga creato un js troppo grande nella build
  computed: {
    allow_registration: function () {
      return mj_config.allow_registration();
    }
  },
  data: () => ({
    showLogged: true,
    show_error: false,
    login_error_text: "",
    valid: true,
    showPassword: false,
    form: {
      password: "",
      email: ""
    },
    lazy: false
  }),
  methods: {
    submit() {
      this.show_error = false;
      this.login_error_text = "";
      if (this.$refs.form.validate()) {
        const user = {
          email: this.form.email,
          password: this.form.password
        };

        store
            .dispatch("login", user)
            .then(() => {
              this.$router.replace("/menu");
            })
            .catch(e => {
              this.show_error = true; //usa arrow function per avere il this del componente
              this.login_error_text = e.response.data.message;
            });
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    logout() {
      this.$store.dispatch("logout").finally(() => {
        this.$router.replace("/utente/login");
      });
    }
  },
  watch: {
    form: {
      handler: function () {
        this.show_error = false;
        return true;
      },
      deep: true
    }
  }
};
</script>
