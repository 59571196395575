<template>
  <v-sheet>
    <div class="text--secondary">{{label}}</div>
    <div class="display-1 primary--text  font-weight-bold">{{sign}} {{(value)  ? new Intl.NumberFormat("it",{
      maximumFractionDigits: 2,

    }).format(value) : 'N.D.'}} </div>
    <div class="small text--disabled">{{hint}}</div>
  </v-sheet>
</template>

<script>
export default {
  props:['label','value','sign','hint'],
  name: "MjResult",


}
</script>

<style scoped>

</style>