<template>
  <v-container>
    <v-app-bar app v-if="$route.meta.header === 'close'" class="fix_height">
      <v-breadcrumbs :items="breadcumbs_items">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
        <!--
          Se ridefinisci lo slot devi ridefinire l'ottica di riconoscimento dell'ultimo elemento
          <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template> -->
      </v-breadcrumbs>
      <v-spacer></v-spacer>
      <v-btn
          fab
          small
          depressed
          color="primary"
          align="right"
          @click="goBack();"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-app-bar>
    <v-app-bar app v-if="$route.meta.header == null" class="fix_height">
      <v-app-bar-nav-icon
          v-if="sidebar_icon_visible"
          @click="sidebar_visible = !sidebar_visible"
      />
      <router-link v-if="show_app_logo" :to="startPage" class="no_underline">
        <v-img
            alt="App Logo"
            class="mr-2 ml-0 pl-0"
            position="left"
            contain
            :src="base_url ? base_url + '/img/logo.png' : ''"
            transition="scale-transition"
            height="64.1"
        />
      </router-link>
      <router-link v-if="show_app_name" :to="startPage" class="no_underline">
        <v-toolbar-title class="d-none d-sm-block">
          {{ app_name }}
        </v-toolbar-title
        >
      </router-link>
      <v-spacer></v-spacer>

      <v-menu
          v-model="not_logged_menu"
          :close-on-content-click="true"
          offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn large fab icon v-show="!is_logged" v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-account-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list nav dense>
            <v-list-item link @click.prevent="$router.push({path:'/utente/login'})">
              <v-list-item-content>
                <v-list-item-title>Login</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item link @click.prevent="$router.push({path:'/utente/registrati'})">
              <v-list-item-content>
                <v-list-item-title>Registrati</v-list-item-title>
              </v-list-item-content>
            </v-list-item>


          </v-list>
        </v-card>

      </v-menu>


      <v-menu
          v-model="user_menu"
          :close-on-content-click="true"
          offset-y

      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" small fab v-show="is_logged" v-bind="attrs"
                 v-on="on" @click="userMenuClick(attrs)">
            <v-avatar v-if="user_avatar" size="40">
              <v-img :src="user_avatar"/>
            </v-avatar>
            <span v-else>{{ user_name[0] }}</span>
          </v-btn>
        </template>
        <v-card>
          <v-list nav dense>
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar v-if="user_avatar">
                  <v-img :src="user_avatar" size="50"/>
                </v-avatar>
                <v-btn v-else color="primary" small fab v-show="is_logged">
                  {{ user_name[0] }}
                </v-btn>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ user_name }}</v-list-item-title>
                <v-list-item-subtitle>{{ role }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item v-if="user_has_credit_enabled">
              <v-list-item-icon>
                <v-icon>mdi-piggy-bank-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Credito: {{ $store.state.token_obj.credit }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link @click.prevent="$router.push({path:'/utente/il_mio_profilo'})">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Il mio Profilo</v-list-item-title>
              </v-list-item-content>
            </v-list-item>


            <v-list-item link @click="logout();">
              <v-list-item-icon>
                <v-icon>mdi-logout-variant</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>

      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
        mobile-breakpoint="800"
        v-model="sidebar_visible"
        app
        v-if="$route.meta.sidebar !== 'hide'"
        class="primary lighten-1"

    >
      <v-list dense class="pt-0 ">
        <!-- //TODO mostra solo su mobile <v-divider dark class="my-4 mt-12" /> -->
        <v-list-item to="/Menu" class="pb-2 pt-2 primary">
          <v-list-item-action class="mr-3 ">
            <v-icon class="text_on_primary_background" style="opacity: 0.5;">mdi-home</v-icon>
          </v-list-item-action>
          <v-list-item-content class="text_on_primary_background second_font">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider dark/>
        <transition name="fade">
          <div class="text-center">
            <v-progress-circular
                class="mt-4"
                indeterminate
                height="16"
                v-if="showLoader"
            ></v-progress-circular>
          </div>
        </transition>
        <MjMenuTree
            v-if="is_logged"
            size="small"
            api-url="/api/menu_items"
            class="px-0 mx-0 pb-0"
            textClass="text_on_primary_background"
        />
      </v-list>
      <template v-slot:append>
        <v-row justify="space-around">
          <v-col v-if="show_theme_switch">
            <v-btn
                class="text_on_primary_background"
                small
                icon
                @click="updateDarkMode()"
            >
              <v-icon right>mdi-invert-colors</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="9" class="mr-2 mt-1">
            <v-btn x-small block v-show="is_logged" @click="logout">
              Logout
              <v-icon right>mdi-logout-variant</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </v-navigation-drawer>
  </v-container>
</template>

<!--suppress CssUnusedSymbol -->
<style>
/*.altezza fissata per evitare un bug di vuetify sull'app-bar*/
.fix_height {
  min-height: 64.1px !important;
  height: 64.1px !important;
}

.fix_height .v-toolbar__content {
  min-height: 64.1px !important;
  height: 64.1px !important;
}

/*fine fix altezza app-bar*/
</style>

<script>
// import mj_axios from "@/mj_axios.js";
import mj_config from "@/mj_config.js"
import MjMenuTree from "@/components/MjMenuTree.vue";

export default {
  name: "Header",
  components: {MjMenuTree},
  props: {
    show_app_name: {default: true},
    show_app_logo: {default: true},
    show_theme_switch: {default: true},
  },
  computed: {
    console: () => console,
    //per essere reattivo lo stato deve essere dentro computed, non dentro data.
    app_name() {
      return this.$store.state.client_settings.APPNAME;
    },
    base_url() {
      return this.$store.state.client_settings.SERVER_BASEURL;
    },
    user_avatar() {
      return this.$store.state.user_avatar;
    },
    user_has_credit_enabled() {
      return mj_config.user_has_credit_enabled();
    },
    user_credit() {


      return this.$store.state.token_obj.credit;

    },
    user_name() {
      let username;
      if (this.$store.state.token_obj.email) {
        if (this.$store.state.token_obj.name && this.$store.state.token_obj.name.length > 0) {
          username = this.$store.state.token_obj.name;
        } else {
          username = this.$store.state.token_obj.email;
        }
      } else {
        username = '';
      }
      return username;
    },
    role() {
      return this.$store.state.token_obj.email
          ? this.$store.state.token_obj.role
          : "";
    },
    is_logged() {
      return (
          this.$store.state.token_obj != null &&
          this.$store.state.token_obj !== ""
      );
    },
    startPage() {
      return this.is_logged ? "/Menu" : "/";
    },
    sidebar_icon_visible() {
      return this.$store.state.token_obj;
    },
    breadcumbs_items() {
      let ret = [];
      if (this.$store.state.modal_navigation_breadcumbs)
        ret = this.$store.state.modal_navigation_breadcumbs.map((item) => {
          return {disabled: false, to: item.path, text: item.meta.title};
        });
      return ret;
    },
  },
  data() {
    return {
      sidebar_visible: null, //null e non false altrimenti non lo apre di default su wide screen
      // items: [],
      showLoader: false,
      user_menu: false,
      not_logged_menu: false,
    };
  },
  created() {
    // this.update_sidebar_items();
    if (!this.is_logged) {
      this.sidebar_visible = false;
    }
  },
  methods: {
    //chiamato quando creo il componente
    //e quando cambia lo stato di login.
    // update_sidebar_items() {

    //   if (this.is_logged) {
    //     this.showLoader = true;
    //     mj_axios
    //       .get("/api/menu_items")
    //       .then(response => {
    //         this.items = Object.values(response.data.data);
    //       })
    //       .catch(() => {})
    //       .then(() => {
    //         this.showLoader = false;
    //         this.sidebar_visible = null;
    //       });
    //   } else {
    //     this.items = [];
    //     this.sidebar_visible = false;
    //   }
    // },
    logout() {
      this.$store.dispatch("logout").finally(() => {
        this.$router.replace("/utente/login");
      });
    },
    updateDarkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      window.localStorage.setItem('dark_mode_on', this.$vuetify.theme.dark === true);
    },
    userMenuClick(attrs) {
      if (!JSON.parse(attrs['aria-expanded'])) {
        //ricarico per avere il credit aggiornato
        this.$store.dispatch('reloadLoggedUser');
      }
    },
    goBack() {
      //chiudo la window se non ho history
      if (window.history.length === 1
      ) {
        //la funzione viene ignorata se non è stato aperto tramite window.open
        window.close();
      }
      this.$router.go(-1)
    }
  }
  ,
  watch: {
    //dacance
    // is_logged: function() {
    //   this.update_sidebar_items();
    // }
  }
  ,
}
;
</script>