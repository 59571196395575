var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"px-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"fixed":""}},[_c('v-snackbar',{attrs:{"app":"","type":"warning","width":"100%","color":"info","vertical":"vertical"},model:{value:(_vm.snack_bar_show),callback:function ($$v) {_vm.snack_bar_show=$$v},expression:"snack_bar_show"}},[_vm._v(_vm._s(_vm.error_text)+" ")])],1),_c('v-toolbar',{staticClass:"gradient_background mb-2 ",attrs:{"elevation":"1","flat":"","rounded":"","dense":""}},[(_vm.back_button_visible)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"shortkey",rawName:"v-shortkey",value:(['meta', 'b']),expression:"['meta', 'b']"}],attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('go-back-click')},"shortkey":function($event){return _vm.$emit('go-back-click')}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-backburger")])],1)]}}],null,false,4224874275)},[_c('span',[_vm._v("Indietro")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"shortkey",rawName:"v-shortkey",value:(['meta', 's']),expression:"['meta', 's']"}],class:[_vm.edit_not_saved ? '' : 'text--lighten-3'],attrs:{"icon":"","color":"primary","small":""},on:{"click":function($event){return _vm.$emit('save-item-click')},"shortkey":function($event){return _vm.$emit('save-item-click')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}])},[_c('span',[_vm._v(_vm._s(this.edit_mode ? 'Aggiorna' : 'Salva'))])]),(_vm.restore_item_show)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"green","icon":""},on:{"click":function($event){return _vm.$emit('restore-item-click')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-right")])],1)]}}],null,false,3290242080)},[_c('span',[_vm._v("Ripristina")])]):_vm._e(),(_vm.new_button_visibile)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({directives:[{name:"shortkey",rawName:"v-shortkey",value:(['meta', '+']),expression:"['meta', '+']"}],attrs:{"icon":"","color":"orange"},on:{"click":function($event){return _vm.$emit('new-item-click')},"shortkey":function($event){return _vm.$emit('new-item-click')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,2534979531)},[_c('span',[_vm._v("Nuovo")])]):_vm._e()],1),(_vm.speedDialVisible)?_c('v-speed-dial',{staticStyle:{"z-index":"777","margin-bottom":"41px","margin-right":"7px"},attrs:{"absolute":"","fixed":"","bottom":true,"right":true,"direction":"top","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"secondary","fab":"","large":""},model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},[(_vm.speedDial)?_c('v-icon',[_vm._v(" mdi-close ")]):_c('span',{staticClass:"speed_dial_closed_text"},[_vm._v(" "+_vm._s(_vm.speedDialTitle)+" ")])],1)]},proxy:true}],null,false,766332250),model:{value:(_vm.speedDial),callback:function ($$v) {_vm.speedDial=$$v},expression:"speedDial"}},[_c('v-btn',{attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('save-item-click')}}},[_c('v-icon',[_vm._v("mdi-content-save")])],1),(_vm.new_button_visibile)?_c('v-btn',{attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){return _vm.$emit('new-item-click')}}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }