import Vue from 'vue'
import VueRouter from 'vue-router'

import {custom_routes} from '@/custom.router.js';

import MenuView from '@/views/MenuView.vue'
import ApiTesterView from '@/views/misc/ApiTesterView.vue'
import TestView from '@/views/misc/TestView.vue'

import UsersTableView from '@/views/admin/UsersTableView.vue'


//user
import LoginView from '@/views/user/LoginView.vue'
import RegistrationView from '@/views/user/RegistrationView.vue'
import RecoverPasswordView from '@/views/user/RecoverPasswordView.vue'
import ResetPasswordView from '@/views/user/ResetPasswordView.vue'
import AccountActivationView from '@/views/user/AccountActivationView.vue'

import MjTableItems from '@/components/MjTableItems.vue'
import RoleFormView from '@/views/user/RoleFormView.vue'
import UserFormView from '@/views/user/UserFormView.vue'


import GenericErrorView from '@/views/GenericErrorView.vue'
import store from '@/store.js'
import mj_config from '@/mj_config.js'
import MyAccountView from "@/views/user/MyAccountView";


Vue.use(VueRouter)
const router = new VueRouter();
const mj_template_routes = [
    {path: '/test_api', component: ApiTesterView, meta: {title: 'Api Tester', role: ['SuperAdmin']}},
    {path: '/test_view', component: TestView, meta: {title: 'Test View', role: ['SuperAdmin', 'Admin']}},

    {path: '/', component: LoginView, meta: {title: 'Login', role: ['Public']}},
    {path: '/utente/login', component: LoginView, meta: {title: 'Login', role: ['Public']}},
    {path: '/utente/recupera_password', component: RecoverPasswordView, meta: {title: 'Recupera Password', role: ['Public']}},
    {path: '/utente/resetta_password', component: ResetPasswordView, meta: {title: 'Resetta Password', role: ['Public']}},
    {path: '/utente/il_mio_profilo', name: 'MyAccountView', component: MyAccountView, meta: {title: 'Il mio Profilo', role: ['SuperAdmin', 'Admin', 'Utente']}},

    {path: '/error', component: GenericErrorView, meta: {title: 'Errore', role: ['Public']}},

    {path: '/menu', component: MenuView, meta: {title: 'Menu', role: ['SuperAdmin', 'Admin', 'Utente']}},

    //Table:  casi specifici
    {path: '/table/User', component: UsersTableView, meta: {title: 'Gestione Utenti', role: ['SuperAdmin', 'Admin']}},


    //e il caso generale
    {path: '/table/:id', component: MjTableItems, meta: {title: 'Tabella', role: ['SuperAdmin', 'Admin']}},

    {path: '/form_role', name: 'NewRoleFormView', component: RoleFormView, meta: {title: 'Scheda Ruolo', role: ['SuperAdmin']}},
    {path: '/form_role/:id', name: 'RoleFormView', component: RoleFormView, meta: {title: 'Scheda Ruolo', role: ['SuperAdmin']}},
    {path: '/form_user', name: 'NewUserFormView', component: UserFormView, meta: {title: 'Scheda Utente', role: ['SuperAdmin', 'Admin']}},
    {path: '/form_user/:id', name: 'UserFormView', component: UserFormView, meta: {title: 'Scheda Utente', role: ['SuperAdmin', 'Admin']}},

];

const allow_registration_paths = [


    {path: '/utente/registrati', component: RegistrationView, meta: {title: 'Registrati', role: ['Public']}},
    {
        path: '/utente/attivazione',
        component: AccountActivationView,
        meta: {title: 'Account Attivato', role: ['Public']}
    },

];

//in caso di duplicati (override di una path) vue router considera il primo definito (quindi custom_routes và prima)
custom_routes.forEach((custom_route) => {
    router.addRoute(custom_route);
});

mj_template_routes.forEach((mj_template_route) => {
    router.addRoute(mj_template_route);
});

if (mj_config.allow_registration()) {

    allow_registration_paths.forEach((allow_registration_path) => {
        router.addRoute(allow_registration_path);
    });
}


//global navigation guard
router.beforeEach(function (to, from, next) {
    (to, from, next)

    const resolve_resp = router.resolve(to);
    if (resolve_resp.resolved.matched.length > 0) {
        //the route is exists.
        document.title = `${(to.meta.title || '')} - ${store.state.client_settings.APPNAME ? store.state.client_settings.APPNAME : ''}`;
        const route_roles = to.meta.role;
        const user_role = store.state.token_obj.role || 'Public'
        //passa se la rotta è pubblica oppure il ruolo e la rotta corrispondono
        //nota bene, che essendo il ruolo modificabile clientside, bisogna sempre controverificare le risorse server side.
        if (route_roles && (route_roles.indexOf(user_role) !== -1 || route_roles.indexOf('Public') !== -1 || user_role === 'SuperAdmin')) {
            next();
        } else {

            //se è loggato allora mostro il modal
            if (store.state.token_obj != null &&
                store.state.token_obj !== "") {
                store.commit('update_modal_dialog', {
                    title: 'Attenzione',
                    text: 'Non sei autorizzato a visualizzare questo contenuto <br> Se non sei loggato o devi cambiare ruolo <a href="/utente/login">Vai al Login</a>',
                    visible: true
                });
            } else { //altrimenti vai al login

                next({
                    path: '/utente/login',
                    params: {nextUrl: to.fullPath}
                })
            }
        }

    } else {
        //the route does not exists.

        next({
            path: '/error',
            query: {msg: 'Pagina NON trovata'},

        })
    }


})
export default router;