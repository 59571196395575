<template>
  <v-container :class="[fullWidth ? 'mx-0 px-0' : '']">
    <v-data-table
        v-model="selected"
        :options="dataTableOptions"
        :headers="headers"
        :items="items"
        :itemsPerPage="itemsPerPage"
        :disable-pagination="!enablePagination"
        :hide-default-footer="hideFooter"
        :server-items-length="serverItemsLength"
        :page.sync="currentPage"
        :items-per-page.sync="itemsPerPage"
        mobile-breakpoint="0"
        @page-count="pageCount = $event"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :loading="showLoader"
        :show-select="
        (item_meta_data.is_selectable || item_meta_data.is_erasable) &&
        items.length > 0
      "
        :show-expand="!!this.item_meta_data.expand_field"
        :expanded.sync="expanded_row"
        :item-class="(item) => item.tr_class"
        no-results-text="Nessun Elemento Trovato.."
        :class="[`elevation-${elevation}`, 'mj_table_items', (fullWidth) ? 'mj_full_width' : 'mj_card_style']"
        @click:row="clickRowHandler"
        @toggle-select-all="toggleSelectAll"
        @update:page="loadItems(true)"
        @update:items-per-page="loadItems(true)"
        @update:sort-by="loadItems(true)"
        @update:sort-desc="loadItems(true)"
        :fixed-header="true"
        height="65vh"
        :hide-default-header="!this.items || this.items.length == 0"
        :footer-props="{
        'items-per-page-options': [50, 100, 500],
      }"
    >
      <template v-slot:no-data>
        <v-container>
          <h2 class="second_font text--secondary">Ops!</h2>
          <h3 class="mb-3 second_font primary--text">Nessun Elemento da Mostrare</h3>
          <v-row no-gutters class="align-content-space-between justify-center">

            <v-btn
                v-if="showWithTrashed && !withTrashed"
                class="mr-2"
                small
                depressed
                outlined
                @click="
                if (!showLoader) {
                  withTrashed = !withTrashed;
                  loadItems();
                }
              "
            >
              Cerca nel cestino
              <v-icon right>mdi-delete-empty</v-icon>
            </v-btn>

            <v-btn
                color="primary"

                small
                @click="$emit('new-item-click');newItemHandler();"
                v-shortkey="['meta', '+']"
                @shortkey="$router.push({ name: 'NewScheda' + modelName })"
            >
              Nuovo elemento
              <v-icon right>mdi-plus</v-icon>
            </v-btn>

          </v-row>
        </v-container>
      </template>

      <template v-slot:top>
        <MjSoftTitle :class="[fullWidth? 'ml-6':'ml-3','mb-6']" :title="'GESTIONE '+
                (item_meta_data.item_plural_name ? item_meta_data.item_plural_name : '')
              " :icon="item_meta_data.icon"></MjSoftTitle>


        <!--Filters section-->

        <slot name="mj_filters_section" :updateFilters="updateFilters"></slot>

        <v-toolbar class="gradient_background mb-2" elevation="1" flat :rounded="!fullWidth" dense>

          <v-layout row justify-left align-center>
            <slot
                name="mj_top_toolbar_prepend"
                :selected_ids="selected_ids"
                :selected="selected"
            ></slot>
            <!-- new item -->
            <v-tooltip top v-if="selected.length == 0 && item_meta_data.can_add_new">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    icon
                    v-on="on"
                    v-bind="attrs"
                    @click="$emit('new-item-click');newItemHandler();"
                    v-shortkey="['meta', '+']"
                    @shortkey="$router.push({ name: 'NewScheda' + modelName })"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Nuovo Elemento</span>
            </v-tooltip>
            <!--refresh-->
            <v-tooltip top v-if="selected.length == 0">
              <template v-slot:activator="{ on }">
                <v-btn
                    icon
                    v-on="on"
                    color="secondary"
                    @click="loadItems()"
                    :disabled="showLoader"
                    v-shortkey="['meta', 'r']"
                    @shortkey="loadItems()"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>Ricarica</span>
            </v-tooltip>
            <!-- mostra i cestinati-->
            <v-tooltip top v-if="selected.length == 0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="blue-grey"
                    icon
                    v-on="on"
                    v-bind="attrs"
                    @click="if (!showLoader) {withTrashed = !withTrashed;loadItems();}"
                >
                  <v-icon>{{ showWithTrashed ? (withTrashed ? 'mdi-delete' : 'mdi-delete-empty') : '' }}</v-icon>
                </v-btn>
              </template>
              <span>{{ (showWithTrashed ? 'Mostra' : 'Nascondi') + ' gli elementi nel cestino' }}</span>
            </v-tooltip>
            <v-tooltip top v-if="csv_global_export_enabled">
              <template v-slot:activator="{ on }">
                <v-progress-circular
                    v-show="csv_exporting"
                    indeterminate
                    color="green"
                ></v-progress-circular>

                <v-btn
                    class="mr-2"
                    v-show="csv_exporting == false"
                    icon
                    small
                    color="green white--text"
                    v-on="on"
                    @click="showExportCsvDialog = true"
                >
                  <v-icon>mdi-table-arrow-down</v-icon>
                </v-btn>
              </template>
              <span>Esporta in Formato CSV (Excel Compatibile)</span>
            </v-tooltip>
            <v-tooltip top v-if="selected.length > 0">
              <template v-slot:activator="{ on }">
                <v-btn
                    class="mr-2"
                    icon
                    small
                    color="danger white--text"
                    v-on="on"
                    @click="delete_confirm_dialog_show = true"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </template>
              <span>Elimina Tutti</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <!-- Search Bar-->
            <v-text-field
                :hint="showSearchHint? 'Trovati ' + serverItemsLength + ' Elementi' : ''"
                persistent-hint
                class="pb-3 mr-5 hidden-xs-only"
                :hide-details="true"
                v-model="search"
                append-icon="mdi-magnify"
                label="Cerca"
                style="max-width: 280px;"
                single-line
                clearable
                v-on:input="showSearchHint = false"
                v-on:keyup.enter="currentPage = 1;loadItems(true);"
                @click:clear="currentPage = 1;search = '';loadItems(true);"

            ></v-text-field>
          </v-layout>

        </v-toolbar>
        <!--        alert selezione multipla across page-->
        <v-row v-show="selected.length > 0">
          <v-col cols="12">
            <v-alert type="info" dense>
              Hai selezionato {{ selected.length }} elementi.<a
                href="#"
                @click.prevent="selected = []"
                class="white--text text-sm float-right"
            >
              Annulla Selezione</a
            >
              <br/>
              <a
                  href="#"
                  @click.prevent="
                  loadAllSelected();
                  select_all_warning = false;
                "
                  v-show="select_all_warning"
                  class="white--text"
              >Clicca per selezionare tutti {{ serverItemsLength }} Elementi
                Trovati</a
              >
            </v-alert>
          </v-col>
        </v-row>

      </template>

      <template v-slot:[`item.actions`]="{ item, index }">

        <v-menu

            z-index="1000000"
            open-on-hover
            left

            nudge-top="8px"
            offset-x
            content-class="actions_vmenu"

        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                v-bind="attrs"
                v-on="on"
                icon
            >
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>

          </template>
          <v-flex class="px-1 py-1 ">
            <slot name="mj_left_item_actions" :item="item"></slot>
            <v-tooltip top :color="item.trashed ? 'danger' : 'warning'">
              <template v-slot:activator="{ on }">
                <v-btn icon  v-if="!!item_meta_data.is_erasable">
                  <v-icon
                      v-on="on"
                      @click.prevent.stop="deleteSingleItem(item)"
                  >{{
                      item.trashed ? "mdi-delete-forever" : "mdi-delete"
                    }}
                  </v-icon
                  >
                </v-btn>
              </template>
              <span>{{
                  item.trashed ? "Elimina Definitivamente" : "Sposta nel Cestino"
                }}</span>
            </v-tooltip>
            <v-tooltip top color="info">
              <template v-slot:activator="{ on }">
                <v-btn icon  v-if="item.trashed" class="mx-2">
                  <v-icon
                      v-on="on"
                      @click.prevent.stop="restoreSingleItem(item, index)"
                  >mdi-archive-arrow-up
                  </v-icon
                  >
                </v-btn>
              </template>
              <span>Ripristina Elemento</span>
            </v-tooltip>
            <slot name="mj_right_item_actions" v-bind="item"></slot>
          </v-flex>
        </v-menu>
      </template>

      <template
          v-if="!!this.item_meta_data.expand_field"
          v-slot:expanded-item="{ headers, item }"
      >
        <td
            class="expanded_cell pa-3 primary lighten-2 white--text"
            :colspan="headers.length"
        >
          {{ item[item_meta_data.expand_field] ? item[item_meta_data.expand_field] : "Nessun Dettaglio Da Mostrare" }}
        </td>
      </template>

      <template v-for="status_col in item_meta_data.status_cols" v-slot:[`item.${status_col}`]="{ item }">
        <v-row
            :key="status_col"
            v-if="item[status_col]"
            :style="{backgroundColor:item[status_col].background_color}"
            justify="center"
            style="height: 100%"
        >
          <v-col :style="{color:item[status_col].text_color}">

            <v-icon :style="{color:item[status_col].text_color}">{{
                item[status_col].icon
              }}
            </v-icon>
            {{ item[status_col].text }}

          </v-col>
        </v-row>
      </template>

      <!--v-for per riportare tutti gli slot presenti nel parent-->
      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>

      <template v-slot:[`body.append`]="{}">
        <tr v-show="showLoader">
          <td colspan="1000" style="height: 4px" class="mx-0 px-0">
            <v-progress-linear indeterminate></v-progress-linear>
          </td>
        </tr>
      </template>

      <!-- <template v-slot:no-data>
        <v-alert type="info" prominent class="my-4 w-100" outlined width="100%" dense>
          <v-row align="center" >
            <v-col class="grow">Nessun Dato Disponibile</v-col>
            <v-col class="shrink">
              <v-btn color="primary" @click="loadItems" :disabled="showLoader">Ricarica</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </template>-->
    </v-data-table>

    <v-row>
      <!--vrow necessaria per la width 100%-->
      <v-snackbar
          app
          v-model="snack_bar_show"
          type="warning"
          width="100%"
          color="info"
          vertical="vertical"
      >{{ error_text }}
      </v-snackbar
      >
    </v-row>

    <MjConfirmDialog
        v-model="delete_confirm_dialog_show"
        @confirm-from-dialog="deleteSelectedItem"
        @cancel-from-dialog="delete_confirm_dialog_show = false"
        dialog_title="Attenzione"
        dialog_text="Sicuro di voler eliminare gli elementi selezionati?"
    ></MjConfirmDialog>

    <MjConfirmDialog
        v-model="restore_confirm_dialog_show"
        @confirm-from-dialog="restoreSingleItemDialog"
        @cancel-from-dialog="restore_confirm_dialog_show = false"
        dialog_title="Attenzione"
        dialog_text="L'elemento è nel cestino, prima di modificarlo devi ripristinarlo, vuoi procedere con il ripristino?"
    ></MjConfirmDialog>

    <MjConfirmDialog
        v-model="showExportCsvDialog"
        @confirm-from-dialog="initCSVDownload"
        @cancel-from-dialog="showExportCsvDialog = false"
        dialog_title="Esportazione CSV"
        dialog_text="Scegli i Campi"
        v-if="items != null && items.length > 0"
    >
      <v-list :max-height="200" style="overflow: auto" dense>
        <v-row align="start" justify="start">
          <template v-for="(csv_header, i) in csv_headers">
            <v-col
                style="flex-grow: 0"
                :key="i"
                v-if="csv_header && csv_header.key && csv_header.key.length > 0"
            >
              <v-checkbox
                  v-model="csv_header.selected"
                  color="primary"
                  :label="csv_header.key"
              ></v-checkbox>
            </v-col>
          </template>
        </v-row>
      </v-list>
    </MjConfirmDialog>
  </v-container>
</template>

<style>

.theme--light .mj_card_style table {
  border: solid 1px #ddd;
  border-radius: 5px 5px;
  overflow: hidden;
}

.theme--dark .mj_card_style table {
  border: solid 1px #222;
  border-radius: 5px 5px;
  overflow: hidden;
}


.mj_card_style th {
  background-color: transparent !important;

}

.mj_full_width table {
}

.actions_vmenu {
  background-color: transparent;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.expanded_cell {
  background-color: white;
  max-width: 10px;
}


.mj_table_items table tr {
  cursor: pointer;
}

.mj_table_items tr.trashed {
  opacity: 0.7;
  background-image: repeating-linear-gradient(135deg, #eee 10px, #eee 12px, transparent 12px, transparent 20px);
}


.v-data-table-header th span {
  opacity: 0.85;
  text-transform: uppercase;
}

.v-data-table {
  background-color: transparent !important
}

.theme--light.v-data-table tbody {
  background-color: white;
}

.theme--dark.v-data-table tbody {
  background-color: black;
}


.theme--light.v-data-table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .01);
}

.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, .03);
}

.theme--dark.v-data-table tbody tr:nth-of-type(even) {
  background-color: rgba(255, 255, 255, .05);
}

/*gestione icona ascii per l'ordinamento*/
.v-data-table-header th[aria-sort='none'] span:hover:after {
  content: '⇅';
  color: var(--v-primary-base);
  opacity: 1;
}

.v-data-table-header th[aria-sort='ascending'] span:after {
  content: '↑';
}

.v-data-table-header th[aria-sort='descending'] span:after {
  content: '↓';
}


/*-----------------------------------------*/


/*nascondiamo l'icona del framework vuetify in quanto genera problemi di layout*/
.v-data-table-header .mdi-arrow-up {
  display: none !important;
}

.actions_cell {
  width: auto;
}
</style>

<script>
import MjConfirmDialog from "@/components/MjConfirmDialog.vue";
import tableMixin from "@/mixins/TableMixin.js";
import mj_config from "@/mj_config.js";
import MjSoftTitle from "@/components/MjSoftTitle";

export default {
  components: {MjSoftTitle, MjConfirmDialog},
  props: {
    "elevation": {default: () => 0},
    "apiUrl": {default: () => '/api/list/'},
    "enable-pagination": {default: true},
    "hide-footer": {default: false},
    "full-width": {default: false},
    modelNameProp: {type: String},
    resourceCollectionName: {type: String},
    newViewNameProp: {type: String},
    customEditItem: {type: Function},
    customDeleteItems: {type: Function},
    customNewItem: {type: Function},
    baseQueryFilter: {type: Array, default: () => []},
    hide_cols: {type: Array},
    hideActions: {default: () => false},
    dataTableOptions: {
      type: Object, default: () => {
      }
    },
    loadItemsOnCreated: {type: Boolean, default: () => true},
    enablePrint: {type: Boolean, default: () => false},
    showWithTrashed: {type: Boolean, default: () => true},
    sort_by: {default: ''},
    sort_desc: {default: false},
    openRowTarget: {default: () => mj_config.defaultTableItemsOpenRowTarget()}
  },
  mixins: [tableMixin],

  computed: {
    csv_global_export_enabled: function () {
      return mj_config.csv_global_export_enabled();
    },
    //override modelName on tableMixin
    // modelName: function () {
    //   return 'modelname';
    // },
    newItemHandler: function () {
      return this.customNewItem ? this.customNewItem : this.newItem;
    },
    clickRowHandler: function () {
      return this.customEditItem ? this.customEditItem : this.editItem;
    },
  },

  data: () => ({


    /*definisci header se vuoi fare un overload*/
    // headers: [
    //   { text: "Id", align: "start", value: "id" },
    // ]
  }),
  methods: {
    deleteSelectedItem: function (item) {

      if (this.customDeleteItems && this.customDeleteItems.length != 0) {

        return this.customDeleteItems(item);
      } else {
        return tableMixin.methods.deleteSelectedItem.call(this, item);
      }
    }
  },
};
</script>

